<template>
  <!-- 列印模式 -->
  <PrintPage
    v-show="mode === 'print'"
    @notifyFromPrintPage="notifyFromPrintPage"
    :printData="printData"
  ></PrintPage>
  <!-- 結單頁面 -->
  <div class="checkout-order" style="height: auto" v-show="mode === 'checkout'">
    <!-- 標題 -->
    <div class="single-page-tag mb-3">
      <p class="sp-start sp-text fw-bolder">
        <span v-if="currentStatus === 'preCheckout'">結單</span>
        <span v-else>配單結單</span>
        <a
          href="https://youtu.be/fw9CFHTMWSU?si=pbHhpx96TgMHoJCs"
          target="_blank"
        >
          <img class="info-icon ms-1" src="@/assets/icon/interrogation.png" />
        </a>
      </p>
    </div>
    <div class="tw-container">
      <!-- 按鈕 -->
      <div class="mb-3 row">
        <div class="col-8 p-0">
          <button
            class="tw-btn tw-btn-secondary me-3"
            @click="showModal('soldOut')"
          >
            顯示斷貨
          </button>
          <button
            class="tw-btn tw-btn-success me-3"
            @click="updateCheckoutQuantity('multiple', 'min')"
          >
            全數歸0
          </button>
          <button
            class="tw-btn tw-btn-success"
            @click="updateCheckoutQuantity('multiple', 'max')"
          >
            全數結單
          </button>
        </div>
        <div class="col-4 p-0 text-end">
          <!-- <router-link class="tw-btn tw-btn-danger me-3" :to="toOldPage"
            >舊版</router-link
          > -->
          <button class="tw-btn tw-btn-secondary me-3" @click="switchMode">
            切換列印模式
          </button>
        </div>
      </div>
      <!-- 訂單列表 -->
      <div class="table-responsive mb-3" style="height: 400px">
        <table class="table table-hover">
          <thead class="float-header">
            <tr>
              <th>封面</th>
              <th class="text-start" style="min-width: 150px">商品</th>
              <th class="text-start" style="min-width: 150px">款式</th>
              <th>數量</th>
              <th style="min-width: 80px">原單價/目前單價</th>
              <th>已結</th>
              <th style="min-width: 80px">已配/未配</th>
              <th style="min-width: 200px">結單</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="!areaLoading">
              <template v-if="merchOrders.size > 0">
                <template v-for="data in merchOrders" :key="data[0]">
                  <tr v-for="item in data[1].merchOrders" :key="item.id">
                    <td>
                      <Image
                        class="table-img"
                        :imageHash="data[1].frontImage"
                        size="s"
                        v-if="data[1].frontImage"
                        :alt="data[1].frontImage"
                        style="width: 50px; height: 50px"
                      ></Image>
                      <img
                        src="@/assets/other-images/noImg.png"
                        v-else
                        style="width: 50px; height: 50px"
                      />
                    </td>
                    <td class="text-start">
                      <p class="mb-2 fw-bolder">
                        {{ data[1].merchandiseName }}
                      </p>
                      <div style="min-width: 200px; max-width: 200px">
                        <p
                          class="pointer ellipsis2 text-secondary can-click"
                          @click="showModal('orderNote', item.note)"
                          v-if="item.note"
                        >
                          訂單備註 : {{ item.note }}
                        </p>
                      </div>
                    </td>
                    <td class="text-start">
                      <span
                        v-for="style in item.merchandiseStyles"
                        :key="style.id"
                      >
                        {{ style.name }}&ensp;
                      </span>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>
                      <p class="mb-2">
                        {{ item.price }} / {{ item.currentPrice }}
                      </p>
                      <p
                        class="text-success fw-bolder"
                        v-if="item.price > item.currentPrice"
                      >
                        符合多件優惠，故單價降至多件優惠價格
                      </p>
                      <p
                        class="text-secondary fw-bolder"
                        v-else-if="item.price < item.currentPrice"
                      >
                        原單價小於等於優惠價格，故保持原單價
                      </p>
                    </td>
                    <td>{{ item.checkOutQuantity }}</td>
                    <td>
                      {{ item.allocatedQuantity }} /
                      {{ item.quantity - item.allocatedQuantity }}
                    </td>
                    <td>
                      <button
                        class="tw-btn tw0btn-secondary"
                        @click="updateCheckoutQuantity('single', 'min', item)"
                      >
                        MIN
                      </button>
                      <input
                        onwheel="this.blur()"
                        class="w-25"
                        type="number"
                        min="0"
                        @input="listenCheckOutInput"
                        v-model="item.currentCheckOutQty"
                        pattern="[0-9]*"
                        oninput="this.value=this.value.replace(/\D/g,'')"
                      />
                      <span>
                        /
                        {{ item.quantity - item.checkOutQuantity }}&ensp;</span
                      >
                      <button
                        class="tw-btn tw0btn-secondary"
                        @click="updateCheckoutQuantity('single', 'max', item)"
                      >
                        MAX
                      </button>
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-else>
                <td colspan="8" class="text-danger fw-bolder text-center">
                  此顧客目前無任何訂單可以結單
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="8" class="text-danger fw-bolder text-center">
                取得資料中...請稍後
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- 顧客備註 -->
      <div class="mb-3 border rounded-3 p-3">
        <p class="tw-border-start fw-bolder mb-3">顧客備註(僅現賣家看到)</p>
        <textarea
          class="form-control"
          cols="10"
          rows="2"
          disabled
          v-model="participantInfo.note"
        ></textarea>
      </div>
      <!-- 結單資訊 -->
      <CheckoutInfo
        :participantId="participantId"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromCheckoutInfoData="fromCheckoutInfoData"
      ></CheckoutInfo>
      <!-- 發票資訊 -->
      <ReceiptInfo
        v-if="!p_loading && customerCheckoutCanSetReceiptInfo"
        :propsToReceiptInfo="propsToReceiptInfo"
        :propsToCheckoutInfo="propsToCheckoutInfo"
        @fromReceiptInfoData="fromReceiptInfoData"
      ></ReceiptInfo>
      <!-- 結單優惠 -->
      <!-- <Promotions
        :participantId="participantId"
        @fromPromotionsData="fromPromotionsData"
      ></Promotions> -->
      <div class="row">
        <!-- 帳單平衡 -->
        <div class="col-md-6 p-0 mb-md-0 mb-5">
          <Balance @fromBalanceData="fromBalanceData"></Balance>
        </div>
        <!-- 金額 -->
        <div class="col-md-6 p-0">
          <div class="mb-3">
            <span class="tw-border-start fw-bolder me-3">金額</span>
          </div>
          <div>
            <ul class="fw-bolder">
              <li class="mb-2">
                運費:
                {{
                  propsToCheckoutInfo.shippingCost
                    ? $methods.numberToFixed(propsToCheckoutInfo.shippingCost)
                    : 0
                }}元
              </li>
              <li class="mb-2">
                帳單平衡: {{ $methods.numberToFixed(balanceTotal) }}元
              </li>
              <li class="mb-2">
                訂單金額: {{ $methods.numberToFixed(merchOrderTotalPrice) }}元
              </li>
              <!-- <li class="mb-2">
                結單優惠:
                <span class="text-success" v-if="selectStoreDiscount">
                  <span v-if="discountAmount"
                    >{{ selectStoreDiscount.name }}(折扣:{{
                      discountAmount
                    }}元)</span
                  >
                  <del class="text-secondary" v-else
                    >{{ selectStoreDiscount.name }}(折扣:{{
                      discountAmount
                    }}元)</del
                  >
                </span>
                <span class="text-danger" v-else>無套用結單優惠</span>
              </li> -->
              <li>
                購物金折抵:
                {{
                  propsToCheckoutInfo.money
                    ? $methods.numberToFixed(propsToCheckoutInfo.money)
                    : 0
                }}元
              </li>
            </ul>
            <hr />
            <div class="d-flex justify-content-between">
              <p class="tw-text-size18 text-danger fw-bolder">
                應付金額: {{ $methods.numberToFixed(finalTotal) }}元
              </p>
              <div class="form-check p-0" v-if="!p_loading && showDetail">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  value=""
                  id="ShowDetail"
                  v-model="d4showDetail"
                />
                <label class="form-check-label fw-bolder" for="ShowDetail">
                  代客直寄
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="pb-2 text-end">
      <button class="tw-btn tw-btn-secondary me-3" @click="goBack">返回</button>
      <button
        class="tw-btn tw-btn-success me-3"
        @click="createBill(false, null)"
      >
        完成
      </button>
      <button
        class="tw-btn tw-btn-success me-3"
        @click="createBill(true, null)"
      >
        完成並發送Notify
      </button>
      <button
        v-if="currentStatus === 'allocatedCheckout'"
        class="tw-btn tw-btn-success"
        @click="showModal('checkoutAndFinish')"
      >
        完成並寄出
      </button>
    </div>
  </div>
  <!-- 斷貨的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="soldOutModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">已斷貨訂單資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>訂單編號</th>
                <th class="text-start">商品</th>
                <th class="text-start">款式</th>
                <th>斷貨數量</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in soldOutList" :key="data.id">
                <td>
                  {{ data.id }}
                </td>
                <td class="text-start">
                  {{ data.merchandise.name }}
                </td>
                <td class="text-start">
                  <span v-for="style in data.merchandiseStyles" :key="style.id">
                    {{ style.name }}&ensp;
                  </span>
                </td>
                <td>{{ data.soldOutQuantity }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 完成並寄出的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkoutAndFinishModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">完成並寄出</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 完成結單通知 -->
          <div class="form-check form-switch p-0 mb-2">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="CheckoutNotify"
              v-model="checkoutNotify"
            />
            <label class="form-check-label" for="CheckoutNotify"
              >完成結單 Notify 通知</label
            >
          </div>
          <!-- 寄出通知 -->
          <div class="form-check form-switch p-0">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              role="switch"
              id="ShippedNotify"
              v-model="shippedNotify"
            />
            <label class="form-check-label" for="ShippedNotify"
              >寄出 Notify 通知</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createBill(checkoutNotify, shippedNotify)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 顯示備註 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="orderNoteModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">訂單備註</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="text-break" style="line-height: 24px">
            {{ showOrderNote }}
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import CheckoutInfo from '../../../components/checkoutOrder/CheckoutInfo.vue'
import Promotions from '../../../components/checkoutOrder/Promotions.vue'
import Balance from '../../../components/checkoutOrder/Balance.vue'
import ReceiptInfo from '../../../components/checkoutOrder/ReceiptInfo.vue'
import PrintPage from '../../../components/checkoutOrder/PrintPage.vue'

export default {
  components: {CheckoutInfo, Promotions, Balance, ReceiptInfo, PrintPage},
  data() {
    return {
      // modal
      soldOutModal: {},
      checkoutAndFinishModal: {},
      orderNoteModal: {},
      // data
      toOldPage: {
        name: "",
        params: {},
        query: {},
      },
      serverToken: '',
      storeId: 0,
      participantId: 0,
      currentStatus: 'preCheckout',
      mode: 'checkout', // print: 列印模式，checkout: 結單模式
      printData: null, // 需列印的資料
      participantInfo: {},
      merchOrders: [],
      soldOutList: [],
      areaLoading: false,
      // 傳給子層(出貨付款資訊)
      propsToCheckoutInfo: {
        selectShppingType: '',
        selectPaymentType: '',
        name: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
        shippingCost: 0,
        D4shippingCost: 0,
        note: '',
        money: 0,
        wallet: 0,
      },
      // 傳給子層(發票資訊)
      propsToReceiptInfo: {
        title: '',
        number: '',
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
      },
      // 結單優惠傳來的資訊
      selectStoreDiscount: null,
      // 帳單平衡過來的訊息
      balances: [],
      balanceTotal: 0,
      // 訂單總金額
      merchOrderTotalPrice: 0,
      // 結單總數
      totalCheckoutQuantity: 0,
      // 折扣金額 (結單優惠)
      discountAmount: 0,
      // 最終金額
      finalTotal: 0,
      // 代客直寄
      d4showDetail: false,
      // 結單通知
      checkoutNotify: false,
      // 寄出通知
      shippedNotify: false,
      // 訂單備註
      showOrderNote: '',
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getParticipantInfo()
  },
  mounted() {
    this.createModals(['soldOutModal', 'checkoutAndFinishModal', 'orderNoteModal'])
  },
  computed: {
    // 偏好 > loading
    p_loading() {
      return this.$store.state.Prefer.p_loading
    },
    // 偏好 > 顧客可自行調整結單數量
    customerCanEditCheckoutQuantity() {
      return this.$store.state.Prefer.p_store.customerCanEditCheckoutQuantity[0].selected
    },
    // 偏好 > 結單可填寫發票資訊
    customerCheckoutCanSetReceiptInfo() {
      return this.$store.state.Prefer.p_store.customerCheckoutCanSetReceiptInfo[0].selected
    },
    // 偏好 > 代客直寄
    showDetail() {
      return this.$store.state.Prefer.p_store.showDetail[0].selected
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.participantId = this.$route.params.participantId
      this.currentStatus = this.$route.query.status
      this.toOldPage = {
        name: "OldCheckoutOrder",
        params: {
          "storeId": this.storeId,
          "participantId": this.participantId,
        },
        query: {
          "status": this.currentStatus
        },
      }
    },
    showModal(status, item) {
      if (status === 'soldOut') {
        // 顯示斷貨
        this.soldOutModal.show()
      } else if (status === 'checkoutAndFinish') {
        // 完成並寄出
        this.checkoutNotify = false
        this.shippedNotify = false
        this.checkoutAndFinishModal.show()
      } else if (status === 'orderNote') {
        // 顯示訂單備註
        this.showOrderNote = item
        this.orderNoteModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得顧客資訊
    getParticipantInfo() {
      this.$methods.switchLoading('show')
      this.areaLoading = true
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 13,
        ids: [this.participantId],
        methods: '{getUser,getCustomerGroup}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            vm.participantInfo = participantInfo
            vm.getParticipantAllMerchOrders();
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.areaLoading = false
        },
      })
    },
    // 取得顧客所有訂單
    getParticipantAllMerchOrders() {
      this.$methods.switchLoading('true')
      const searchData = {
        storeId: this.storeId,
        participantIds: [this.participantId]
      }
      this.$api.merchOrder.getDataTable(searchData).then(res => {
        console.log(res)
        if (res.code === '200') {
          const merchOrderList = res.data
          let merchOrderIds = []
          merchOrderList.forEach(item => {
            // 斷貨
            const soldOutQuantity = item.soldOutQuantity ?? 0
            const quantity = item.quantity ?? 0
            const checkOutQuantity = item.checkOutQuantity ?? 0
            if (soldOutQuantity > 0 || (quantity > 0 && quantity > checkOutQuantity)) merchOrderIds.push(item.merchOrderId)
          })
          this.getMerchOrders(merchOrderIds)
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得詳細資訊的訂單
    getMerchOrders(merchOrderIds) {
      const vm = this
      const getMerchOrdersApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 26,
        ids: merchOrderIds,
        methods: '{getMerchandise{getMerchandisePictureLinks},getShippingQuantity,getCheckOutQuantity,getMerchandiseStyles{getMerchandiseDiscounts}}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchOrdersApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchOrderList = res.data[0].objects
            vm.sortOutData(merchOrderList)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.areaLoading = false
        },
      })
    },
    // 整理資料
    sortOutData(merchOrderList) {
      this.$methods.switchLoading('show')
      // 整理訂單
      this.merchOrders = []
      this.soldOutList = []
      let merchOrdersMap = new Map()
      merchOrderList.forEach(item => {
        if (item.soldOutQuantity > 0) this.soldOutList.push(item)
        // 預購結單/配單結單
        if (item.quantity > 0 && item.quantity > item.checkOutQuantity) {
          if (!merchOrdersMap.has(item.merchandiseStyles[0].id)) {
            if (this.currentStatus === 'preCheckout') {
              // 預購結單
              let object = {
                frontImage: null,
                merchandiseName: item.merchandise.name,
                merchandiseStyles: item.merchandiseStyles,
                discounts: item.merchandiseStyles[0].merchandiseDiscounts,
                merchOrders: [],
              }
              item.merchandise.merchandisePictureLinks.some(item => {
                if (item.front) return object.frontImage = item.imageHash
              })
              object.merchOrders = [{
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
                merchandiseId: item.merchandiseId,
                note: item.note
              }]
              merchOrdersMap.set(item.merchandiseStyles[0].id, object)
            } else if (this.currentStatus === 'allocatedCheckout' && item.allocatedQuantity - item.shippingQuantity > 0 && item.allocatedQuantity > item.checkOutQuantity) {
              // 配單結單
              let object = {
                frontImage: null,
                merchandiseName: item.merchandise.name,
                merchandiseStyles: item.merchandiseStyles,
                discounts: item.merchandiseStyles[0].merchandiseDiscounts,
                merchOrders: [],
              }
              item.merchandise.merchandisePictureLinks.some(item => {
                if (item.front) return object.frontImage = item.imageHash
              })
              object.merchOrders = [{
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
                merchandiseId: item.merchandiseId,
                note: item.note
              }]
              merchOrdersMap.set(item.merchandiseStyles[0].id, object)
            }
          } else {
            if (this.currentStatus === 'preCheckout') {
              // 預購結單
              merchOrdersMap.get(item.merchandiseStyles[0].id).merchOrders.push({
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
                merchandiseId: item.merchandiseId,
                note: item.note
              })
            } else if (this.currentStatus === 'allocatedCheckout' && item.allocatedQuantity > 0) {
              // 配單結單
              merchOrdersMap.get(item.merchandiseStyles[0].id).merchOrders.push({
                allocatedQuantity: item.allocatedQuantity,
                checkOutQuantity: item.checkOutQuantity,
                id: item.id,
                price: item.price,
                quantity: item.quantity,
                soldOutQuantity: item.soldOutQuantity,
                currentCheckOutQty: 0,
                currentPrice: 0,
                merchandiseStyles: item.merchandiseStyles,
                merchandiseId: item.merchandiseId,
                note: item.note
              })
            }
          }
        }
      });
      this.merchOrders = merchOrdersMap
      console.log("merchOrdersMap size: " + merchOrdersMap.size)
      this.updateCheckoutQuantity('multiple', 'max')
      // 傳遞資料到子層 <出貨付款資訊>
      this.propsToCheckoutInfo = {
        selectShppingType: '',
        selectPaymentType: '',
        name: this.participantInfo.user.name,
        phone: '',
        selectCity: '',
        selectArea: '',
        address: '',
        shippingCost: 0,
        D4shippingCost: 0,
        note: '',
        money: 0,
        wallet: this.participantInfo.wallet,
        shopNumber: '',
        shopName: ''
      }
      this.$methods.switchLoading('hide')
      this.areaLoading = false
    },
    // 監聽結單輸入框
    listenCheckOutInput() {
      this.countMerchOrderTotalPrice()
    },
    // max / min
    updateCheckoutQuantity(mode, status, item) {
      if (mode === 'single') {
        if (status === 'min') {
          item.currentCheckOutQty = 0
        } else {
          if (this.currentStatus === 'allocatedCheckout') item.currentCheckOutQty = Math.max(item.allocatedQuantity - item.checkOutQuantity, 0)
          else item.currentCheckOutQty = item.quantity - item.checkOutQuantity
        }
      } else {
        if (status === 'min') {
          for (const [key, value] of this.merchOrders) {
            value.merchOrders.forEach(item => {
              item.currentCheckOutQty = 0
            })
          }
        } else {
          for (const [key, value] of this.merchOrders) {
            value.merchOrders.forEach(item => {
              if (this.currentStatus === 'allocatedCheckout') item.currentCheckOutQty = Math.max(item.allocatedQuantity - item.checkOutQuantity, 0)
              else item.currentCheckOutQty = item.quantity - item.checkOutQuantity
            })
          }
        }
      }
      this.countMerchOrderTotalPrice()
    },
    // * 子層通知 (start)
    // 來自出貨付款的訊息 (子層 > 父層)
    fromCheckoutInfoData(object) {
      this.propsToCheckoutInfo = object
      this.countMerchOrderTotalPrice()
    },
    // 來自結單優惠的訊息 (子層 > 父層)
    // fromPromotionsData(object) {
    //   this.selectStoreDiscount = object
    //   this.countMerchOrderTotalPrice()
    // },
    // 來自帳單平衡的訊息 (子層 > 父層)
    fromBalanceData(array) {
      this.balances = array
      this.countMerchOrderTotalPrice()
    },
    // 來自發票資訊的訊息 (子層 > 父層)
    fromReceiptInfoData(object) {
      this.propsToReceiptInfo = object
    },
    // 來自列印模式的通知 (子層 > 父層)
    notifyFromPrintPage(object) {
      switch (object.option) {
        case 'switchMode':
          this.mode = object.value
          break;
        default:
          throw new Error('checkoutOrder.vue > notifyFromPrintPage > object.option 未處理')
      }
    },
    // * 子層通知 (end)
    // 計算優惠與價格
    countMerchOrderTotalPrice() {
      console.log('計算訂單總價...')
      // 訂單總價
      this.merchOrderTotalPrice = 0
      // 最後加上結單優惠的訂單總價
      this.hasStoreDiscountMerchOrderTotalPrice = 0
      // 總共買幾件
      this.totalCheckoutQuantity = 0
      // 套用結單優惠會折抵多少
      this.discountAmount = 0
      const participantVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      for (const [key, value] of this.merchOrders) {
        // 紀錄總數量
        let totalQuantity = 0
        // 紀錄最便宜的價格
        let cheapPrice = value.merchandiseStyles[0].price
        value.merchOrders.forEach((item, index) => {
          const canCheckoutQuantity = item.quantity - item.checkOutQuantity
          let currentCheckOutQty = item.currentCheckOutQty ? item.currentCheckOutQty : 0
          if (currentCheckOutQty > canCheckoutQuantity) {
            currentCheckOutQty = canCheckoutQuantity
            item.currentCheckOutQty = canCheckoutQuantity
          }
          totalQuantity += currentCheckOutQty
          this.totalCheckoutQuantity += currentCheckOutQty
          // 代表已經到迴圈最後了，可以開始計算最終價格
          if (index === value.merchOrders.length - 1) {
            if (value.discounts) {
              value.discounts.forEach(discount => {
                const minQuantity = discount.minQuantity
                const maxQuantity = discount.maxQuantity
                const discountVipId = discount.storeVipId ? discount.storeVipId : 0
                let inPurchaseInterval = false
                if (minQuantity && maxQuantity) inPurchaseInterval = minQuantity <= totalQuantity && totalQuantity <= maxQuantity
                else inPurchaseInterval = minQuantity <= totalQuantity
                const idVipInterval = participantVipId >= discountVipId
                // 先找出最優惠的價格
                if (inPurchaseInterval && idVipInterval && cheapPrice > discount.price) cheapPrice = discount.price
              })
            }
          }
        })
        // 再將最優惠的價格跟訂單原先買的價格做比對 (todo 在這邊做文字呈現)
        value.merchOrders.forEach(item => {
          if(item.price > cheapPrice) item.currentPrice = cheapPrice
          else item.currentPrice = item.price
          this.merchOrderTotalPrice += item.currentPrice * item.currentCheckOutQty
        })
      }
      this.hasStoreDiscountMerchOrderTotalPrice = this.merchOrderTotalPrice
      // * 若有結單優惠 (暫定不上架結單優惠，但已完成)
      if (this.selectStoreDiscount) {
        const condition = this.selectStoreDiscount.storeDiscountConditions[0]
        switch (condition.discountType) {
          case 'FULL_AMOUNT_REBATE':
            // 滿額折抵
            if (condition.progressive) {
              // 累進
              if (this.merchOrderTotalPrice >= condition.threshold) {
                this.discountAmount = Math.floor(this.merchOrderTotalPrice / condition.threshold) * condition.discount
                this.hasStoreDiscountMerchOrderTotalPrice = this.merchOrderTotalPrice - this.discountAmount
              }
            } else {
              // 不累進
              if (this.merchOrderTotalPrice >= condition.threshold) {
                this.hasStoreDiscountMerchOrderTotalPrice = this.merchOrderTotalPrice - condition.discount
                this.discountAmount = condition.discount
              }
            }
            break;
          case 'FULL_AMOUNT_DISCOUNT':
            // 滿額打折
            if (this.merchOrderTotalPrice >= condition.threshold) {
              this.hasStoreDiscountMerchOrderTotalPrice = Math.round(this.merchOrderTotalPrice * (condition.discount / 10))
              this.discountAmount = this.merchOrderTotalPrice - this.hasStoreDiscountMerchOrderTotalPrice
            }
            break;
        }
      }
      this.countFinalTotal()
    },
    // 計算總價
    countFinalTotal() {
      console.log('計算總金額...')
      this.finalTotal = 0
      const shippingCost = this.propsToCheckoutInfo.shippingCost ? this.$methods.numberToFixed(this.propsToCheckoutInfo.shippingCost) : 0
      const merchOrderTotal = this.$methods.numberToFixed(this.hasStoreDiscountMerchOrderTotalPrice)
      const money = this.propsToCheckoutInfo.money ? this.$methods.numberToFixed(this.propsToCheckoutInfo.money) : 0
      let balance = 0
      this.balances.forEach(item => {
        const money = item.money ? item.money : 0
        balance += money
      })
      this.balanceTotal = this.$methods.numberToFixed(balance)
      this.finalTotal = this.$methods.numberToFixed(shippingCost + merchOrderTotal - money + balance)
      // * 若有結單優惠 (暫定不上架結單優惠，但已完成)
      if (this.selectStoreDiscount) {
        const condition = this.selectStoreDiscount.storeDiscountConditions[0]
        switch (condition.discountType) {
          case 'FULL_AMOUNT_REBATE_SHIPPING_COST':
            // 滿額免運
            if (merchOrderTotal >= condition.threshold) {
              this.finalTotal -= shippingCost
              this.discountAmount = shippingCost
            }
            break;
          case 'FULL_QUANTITY_REBATE_SHIPPING_COST':
            // 滿件免運
            if (this.totalCheckoutQuantity >= condition.threshold) {
              this.finalTotal -= shippingCost
              this.discountAmount = shippingCost
            }
            break;
        }
      }
    },
    // 建立訂單
    createBill:_.debounce(function(checkoutNotify, shippedNotify) {
      this.$methods.switchLoading('show')
      const vm = this
      const createBillApi = `${process.env.VUE_APP_API}/bill/create`
      const header = {
        authorization: this.serverToken
      }
      let note = ''
      if (this.propsToCheckoutInfo.shopNumber) note += `門市編號: ${this.propsToCheckoutInfo.shopNumber}\n`
      if (this.propsToCheckoutInfo.shopName) note += `門市名稱: ${this.propsToCheckoutInfo.shopName}\n`
      if (this.propsToCheckoutInfo.note) note += this.propsToCheckoutInfo.note
      const billHeader = {
        storeId: this.storeId,
        buyerId:this.participantId,
        note: note,
        shippingType: this.propsToCheckoutInfo.selectShppingType ? this.propsToCheckoutInfo.selectShppingType.split(',')[0] : null,
        shippingCost: this.propsToCheckoutInfo.shippingCost ? this.propsToCheckoutInfo.shippingCost : 0,
        recipient: this.propsToCheckoutInfo.name,
        recipientPhoneNumber: this.propsToCheckoutInfo.phone ? this.propsToCheckoutInfo.phone : null,
        recipientPostalCode: this.propsToCheckoutInfo.selectArea ? this.propsToCheckoutInfo.selectArea : null,
        receivingAddress: this.propsToCheckoutInfo.address,
        paymentType: this.propsToCheckoutInfo.selectPaymentType ? this.propsToCheckoutInfo.selectPaymentType : null,
        walletUseAmount: this.propsToCheckoutInfo.money ? this.propsToCheckoutInfo.money : 0,
        receiptTitle: this.propsToReceiptInfo.title ? this.propsToReceiptInfo.title : null,
        receiptBusinessAdminNumber: this.propsToReceiptInfo.number ? this.propsToReceiptInfo.number : null,
        receiptPhoneNumber: this.propsToReceiptInfo.phone ? this.propsToReceiptInfo.phone : null,
        receiptPostalCode: this.propsToReceiptInfo.selectArea ? this.propsToReceiptInfo.selectArea : null,
        receiptAddress: this.propsToReceiptInfo.address ? this.propsToReceiptInfo.address : null,
        showDetail: this.d4showDetail,
        // discountName: this.selectStoreDiscount ? this.selectStoreDiscount.name : null,
        // discountType: this.selectStoreDiscount ? this.selectStoreDiscount.storeDiscountConditions[0].discountType : null,
        // discountAmount: this.discountAmount ? parseInt(`-${this.discountAmount}`) : 0
      }
      let bodies = []
      for (const [key, value] of this.merchOrders) {
        value.merchOrders.forEach(item => {
          if (item.currentCheckOutQty > 0) {
            bodies.push({
              merchOrderId: item.id,
              price: this.$methods.numberToFixed(item.currentPrice),
              quantity: item.currentCheckOutQty
            })
          }
        })
      }
      let balances = []
      this.balances.forEach(item => {
        const money = item.money ? item.money : 0
        if (money !== 0) {
          balances.push({
            description: item.reson ? item.reson : null,
            money: item.money
          })
        }
      })
      const data = {
        header: billHeader,
        bodies: bodies,
        balances: balances,
        notify: checkoutNotify
      }
      console.log(data)
      // 若與到系統卡頓讓他有機會再點第二次話就擋住
      if (this.checkAjaxQueue("/bill/create", 1)) return this.SweetAlert("other", "正在建立帳單中...請稍後")
      console.log(this.recordAjax)
      const recordAjax = $.ajax({
        type: 'POST',
        async: true,
        url: createBillApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            if (shippedNotify !== null) {
              vm.finishBill(res.data)
            } else {
              vm.$methods.switchLoading('hide')
              vm.goBack()
            }
            console.log(this.recordAjax)
            vm.removeAjaxQueue("/bill/create", 1)
          }
        },
        error: function(err) {
          console.log(err)
          if (err.responseJSON.code === '400-6' && err.responseJSON.message === '商品成團數不足') {
            let errorMessage = `${err.responseJSON.message}: `
            err.responseJSON.errorData.forEach(item => {
              errorMessage += `${item.merchandise.name}: 目前累計: ${item.accumulatedQuantity}單 `
            })
            vm.SweetAlert(err.responseJSON.code, errorMessage)
          } else {
            vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          }
          vm.removeAjaxQueue("/bill/create", 1)
          vm.$methods.switchLoading('hide')
        },
      })
      this.addAjaxQueue("/bill/create", 1, recordAjax)
    }, 2000, {
      leading: true, // 在延遲開始前，立即調用
      trailing: false, // 在延遲結束前，保證不再調用
    }),
    // 完成帳單
    finishBill(billInfo) {
      const vm = this
      const updateHeaderStatusApi = `${process.env.VUE_APP_API}/bill/updateHeaderStatus`
      const header = {
        authorization: this.serverToken
      }
      let data = [{
        id: billInfo.id,
        walletUsed: {
          status: true,
          notify: false
        },
        paid: {
          status: true,
          notify: false
        },
        packaged: {
          status: true,
          notify: false
        },
        shipped: {
          status: true,
          notify: this.shippedNotify
        }
      }]
      console.log(data)
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateHeaderStatusApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.checkoutAndFinishModal.hide()
            vm.goBack()
            vm.$methods.switchLoading('hide')
          } else {
            vm.handleUpdateHeaderStatus(res)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 處理"一件設定帳單完成"的結果
    handleUpdateHeaderStatus(res) {
      let message = '此次結單已完成但寄出帳單發生了: '
      // 處理有錯誤的帳單
      res.errorData.forEach(item => {
        message += `${item.message}`
        message += '<br/>'
      })
      message += `<p>3 秒後將自動跳出頁面</p>`
      this.SweetAlert(res.code, message)
      this.checkoutAndFinishModal.hide()
      this.$methods.switchLoading('hide')
      setTimeout(() => {
        this.goBack()
      }, 3000)
    },
    // 切換列印模式
    switchMode() {
      this.printData = {
        merchandises: new Map(),
        buyerInfo: null,
        balances: [],
        amountInfo: null,
        totalCheckoutQuantity: 0
      }
      // 整理訂單資訊
      this.merchOrders.forEach(sameMainStyleOrder => {
        sameMainStyleOrder.merchOrders.forEach(merchOrder => {
          // 整理商品
          if (!this.printData.merchandises.has(merchOrder.merchandiseId)) {
            this.printData.merchandises.set(merchOrder.merchandiseId, {
              frontPicture: null,
              merchandiseName: null,
              totalCheckoutQuantity: 0,
              styles: new Map()
            })
          }
          const merchandiseMap = this.printData.merchandises.get(merchOrder.merchandiseId)
          merchandiseMap.frontPicture = sameMainStyleOrder.frontImage
          merchandiseMap.merchandiseName = sameMainStyleOrder.merchandiseName
          // 整理同款式
          let combineStyleId = ''
          let styleName = ''
          merchOrder.merchandiseStyles.forEach((style, index, arr) => {
            combineStyleId += style.id
            styleName += style.name
            if (index < arr.length - 1) {
              combineStyleId += ','
              styleName += ','
            }
          })
          if (!merchandiseMap.styles.has(combineStyleId)) {
            merchandiseMap.styles.set(combineStyleId, {
              styleName: null,
              currentPrice: 0,
              currentCheckOutTotalQty: 0,
            })
          }
          merchandiseMap.styles.get(combineStyleId).styleName = styleName
          merchandiseMap.styles.get(combineStyleId).currentPrice = merchOrder.currentPrice
          merchandiseMap.styles.get(combineStyleId).currentCheckOutTotalQty += merchOrder.currentCheckOutQty
          // 計算單商品的目前結單總數
          merchandiseMap.totalCheckoutQuantity += merchOrder.currentCheckOutQty
          // 計算這次結單的結單總數
          this.printData.totalCheckoutQuantity += merchOrder.currentCheckOutQty
        })
      })
      // 整理使用者資訊
      this.printData.buyerInfo = {
        buyerName: this.propsToCheckoutInfo.name ? this.propsToCheckoutInfo.name : null,
        buyerPhone: this.propsToCheckoutInfo.phone ? this.propsToCheckoutInfo.phone : null,
        billNote: this.propsToCheckoutInfo.note ? this.propsToCheckoutInfo.note : null
      }
      // 整理帳單平衡
      const balances = this.balances.filter(balance => { return balance.reson && balance.money !== 0 })
      this.printData.balances = balances
      // 整理總金額資訊
      this.printData.amountInfo = {
        shippingCost: this.propsToCheckoutInfo.shippingCost ? this.propsToCheckoutInfo.shippingCost : 0,
        balanceTotal: this.balanceTotal ? this.balanceTotal : 0,
        merchOrderTotalPrice: this.merchOrderTotalPrice ? this.merchOrderTotalPrice : 0,
        money: this.propsToCheckoutInfo.money ? this.propsToCheckoutInfo.money : 0,
        finalTotal: this.finalTotal ? this.finalTotal : 0
      }
      console.log(this.printData)
      this.mode = 'print'
    },
    // 返回
    goBack() {
      this.$router.push(this.$methods.go('sellerCheckoutOrder'))
    },
  },
}
</script>

<style lang="scss" scoped>
.ellipsis2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*行數*/
  -webkit-box-orient: vertical;
  word-break: break-all; /*針對英文 */
}
</style>